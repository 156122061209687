import { AbcOutlined } from "@mui/icons-material"
import Footer from "../footer/footer"
import Header from "../header/header"
import './aboutus.css'
import myImage1 from '../../assets/images/about_us.png';
import myImage2 from '../../assets/images/about_us_1.png';

const AboutUs = ()=>{
    return(
        <div>
            <div className="about-us">
                <Header />
                <div className="first-about-us" style={{justifyContent: "center"}}>
                    <div className="first-about-form">
                        <h2><strong>About Us</strong></h2>
                        <p>In today's fast-paced world, assessing an individual's competency can be more challenging than the entire training process itself. Competency is crucial in business, training, and education. To streamline this process and reduce the time spent on knowledge evaluation, we developed the tykhere application. Our platform helps you transition from traditional paper assessments to a more eco-friendly approach, making assessment creation easy, enjoyable, and engaging.</p>
                    </div>

                    <div className="first-about-form2">
                        <img style={{width: "400px"}} src={myImage1}/>
                    </div>
                </div>

                <div className="second-about-us" style={{justifyContent: "center"}}>
                    <div className="second-about-form">
                        <img style={{width: "400px"}} src={myImage2}/>
                    </div>

                    <div className="second-about-form2">
                        <h2><strong>Vision</strong></h2>
                            <p>To be a industry leading company offering unrivalled, high quality and easy assessment evaluation platform and solutions for learning.</p>

                        <h2><strong>Mission</strong></h2>
                            <p>To deliver exceptional platform experience that will meet and exceed our user expectations.</p>    
                    </div>

                </div>
         
        </div>
        <br /><br />
            <Footer />
        </div>
    )
}
export default AboutUs